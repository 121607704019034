import React, {useEffect} from "react";
import * as R from 'ramda';
import {useIsMobile} from "../utils";
import Hero from "../components/hero";
import Story from "../components/story";
import {client} from "../client";
import {navigate} from "gatsby";
import { SEO } from '../components/seo';


const Subscribe = () => {
        const {isMobile, screenWidth} = useIsMobile();

        useEffect(() => {
            client.request(`
          query {
  products(first: 100, query:"product_type:咖啡訂閱") {
    edges {
      node {
        id
        tags
        handle
        title
        availableForSale
        variants(first: 50) {
          edges {
            node {
              id
              title
              priceV2 {
                amount
              }
            }
          }
        }
        images(first: 10) {
          edges {
            node {
              url(transform: { 
                crop: CENTER
                maxWidth: 500,
                maxHeight: 500})
              id
              thumbnailUrl: url(transform: { 
                crop: CENTER
                maxWidth: 360,
                maxHeight: 360})
            }
          }
        }
      }
    }
}
          }
        `).then((data) => {
            });

        }, [])


    return (
        <div>
            <Hero 
							images={[
								'https://cdn.shopify.com/s/files/1/0645/0436/5310/files/subscribe-hero-1.jpg',
								'https://cdn.shopify.com/s/files/1/0645/0436/5310/files/subscribe-hero-2.jpg',
							]}
						/>
            <div
                style={{
                    padding: '4rem 4rem 0 4rem',
                    maxWidth: 732,
                    textAlign: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0px auto',
                }}
                className="title3"
            >
                SUBSCRIBE
                <br/>
                <div className="title1" style={{fontSize: 40}}>
                            訂閱咖啡豆
                </div>
                <div 
                className="body1"
                style={{textAlign: 'justify', padding: isMobile ? '0 16px' : '64px 0'}}>
                    合作咖啡提供咖啡豆訂閱服務，讓咖啡愛好者每個月輕鬆收到最新鮮的咖啡豆，適合家庭、公司等用量較大的客戶。
                </div>
            </div>
            <br/>
                <Story
                  imageUrl={'https://cdn.shopify.com/s/files/1/0645/0436/5310/files/subscribe-1.jpg?width=1024'}
                    header="訂閱方式"
                    content={
                        <div style={{
                            display: 'flex', 
                            flexDirection: 'column',
                            className: 'body1',
                        }}>
                            <p>
                                1.每個月的第一周收到我們的咖啡豆。
                                <br/>
                                2.每個月的 15 號前訂閱，當月就可以收到我們的咖啡豆。
                                <br/>
                                3.每個月的 15 號後訂閱，會在下個月的第一周收到我們的咖啡豆。
                                <br/>
                                4.國外的訂閱客戶會依照當下航運運送狀況，告知最後送達時間。
                            </p>
                            <div className="body2">
                                沖煮器具與研磨：
                            </div>
                            <p>
                                告訴我們您使用的器具，我們可以提供研磨服務。
                            </p>
                            <div className="body2">
                                養豆時間：
                            </div>
                            <p>
                                手沖養豆時間：建議 7 天
                                <br/>
                                濃縮養豆時間：建議 14 天
                            </p>
                            <div className="body2">
                                運輸費用和方式：
                            </div>
                            <p>
                                運費不包含在咖啡豆價格中，會在會後結帳時計算。
                                <br/>
                                國內可以選擇店到店、中華郵政以及宅急便，國外皆使用中華郵政寄送。
                            </p>
                            <div className="body2">
                                取消訂閱：
                            </div>
                            <p>
                                如果需要取消訂閱，請在每個月的 15 號前寄電子郵件或打電話給我們，我們會幫你取消。
                            </p>
                        </div>
                    }
                />
                <div style={{height: 64}}/>
                <div style={{
                    width: '100%',
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>

                    <div style={{
                        width: '68%',
                        flexDirection: 'column',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: 128,
                    }}>
                        <div className="title2">
                            選擇咖啡豆
                        </div>
                        <div
                            style={{width: '100%', height: 40}}
                        ></div>
                        <div style={{
                            width: '100%',
                            flexDirection: isMobile ? 'column' : 'row',
                            display: 'flex',
                            gap: 16,
                            justifyContent: 'center'
                        }}>
                            {
                                R.pipe(
                                    R.map(
                                        (e) => (
                                            <div
                                                style={{
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                  navigate(e.productUrl);
                                                }}
                                            >
                                                <div style={{
                                                    flexGrow: 1,
                                                    display: 'flex',
                                                    padding: isMobile ? 16 : '4rem',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                    border: '1px solid',
                                                    borderRadius: '6px'
                                                }}>
                                                    <img 
                                                      src={e.imageUrl}
                                                      style={{ width: 300, height: 320, objectFit: 'cover' }}
                                                    ></img>
                                                    <div style={{ height: 32 }} />
                                                    <div className="title3">
                                                        {e.english}
                                                    </div>
                                                    <div className="title2">{e.title}</div>
                                                    <br/>
                                                    <div 
                                                      className="body1"
                                                      style={{maxWidth: 300, minHeight: 60, textAlign: 'justify'}}>
                                                        {e.description}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    ),
                                )(
                                    [
                                        {
                                            id: 'single',
                                            title: "單品咖啡",
                                            description: "您可以選擇自己喜歡的時間訂閱我們的咖啡，我們會依照客人喜歡得風味，一個月推薦三款 1/4 磅的咖啡豆。如果沒有咖啡器具，我們會幫您做成掛耳式咖啡，方便在任何地方沖煮咖啡。",
                                            english: "Single Origin Coffee",
                                          imageUrl: 'https://cdn.shopify.com/s/files/1/0645/0436/5310/products/1_c3508a04-e6f1-4406-ae10-81ea86107a0d_500x500_crop_center.jpg?v=1672742096',
                                            productUrl: '/products/單品咖啡訂閱',
                                        },
                                        {
                                            id: 'espresso',
                                            title: "濃縮咖啡",
                                            description: "您可以自行選擇需要的磅數以及需要的時間訂閱義式咖啡豆，我們的義式配方豆適合任何方式沖煮，有兩款烘焙程度可以做選擇。",
                                            english: "Espresso blend",
                                          imageUrl: 'https://cdn.shopify.com/s/files/1/0645/0436/5310/products/107f14e8f00f9452573a14ccd7ca866b_500x500_crop_center.jpg?v=1672743685',
                                            productUrl: '/products/濃縮咖啡訂閱',
                                        },
                                    ]
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
;

export default Subscribe;
export const Head = () => (
  <SEO
    title="訂閱咖啡豆"
    description="合作咖啡提供咖啡豆訂閱服務，讓咖啡愛好者每個月輕鬆收到最新鮮的咖啡豆，適合家庭、公司等用量較大的客戶。"
    image="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/subscribe-hero-2.jpg"
  />
)
